import React from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Button from 'components/button/button'
import SectionHeader from 'components/new/section-header'
import P from 'components/new/typography/p'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Spacer from 'components/new/spacer'
import convertArrayToInlineList from 'utils/convert-array-to-inline-list'

const LocationsSection = () => {
  const {
    allSanityLocation: { states: allSanityLocationStates, totalCount: locationCount },
    backgroundImage,
  } = useStaticQuery(graphql`
    {
      allSanityLocation(
        filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
      ) {
        states: group(field: state) {
          totalCount
          fieldValue
        }
        totalCount
      }
      backgroundImage: file(relativePath: { eq: "newberry-storefront-cutout.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  allSanityLocationStates.sort((a, b) => b.totalCount - a.totalCount)

  const statesList = allSanityLocationStates.map(state => state.fieldValue)

  const statesListString = convertArrayToInlineList(statesList)

  return (
    <Container>
      <div className='content-container'>
        <SectionHeader>Find a store</SectionHeader>
        <P>
          Hutson has {locationCount} locations across {statesListString}. Find one near you.
        </P>
        <Spacer size='s' />
        <Button as={ButtonLink} to='/locations/' color='green' ghost>
          Find a store
        </Button>
      </div>
      <GatsbyImage
        image={getImage(backgroundImage)}
        objectFit='contain'
        objectPosition='center bottom'
        alt=''
        style={{ width: '100%' }}
      />
    </Container>
  )
}

const Container = styled.div`
  background-color: ${props => props.theme.color.n10};
  position: relative;

  .content-container {
    margin: 0 auto;
    max-width: 540px;
    padding: 48px 24px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    p {
      margin-top: 0;
      text-align: center;
    }
  }

  @media (min-width: 900px) {
    .content-container {
      padding: 64px;
    }
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  margin: 0 auto;
  text-decoration: none;
`

export default LocationsSection
