import React, { useEffect, useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import H2 from 'components/typography/h2'
import LocationsSection from 'components/locations-section'
import Button from 'components/button'
import UnstyledButton from 'components/button/unstyled-button'
import { useInView } from 'react-intersection-observer'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'
import scrollTo from 'utils/scroll-to'
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp'
import { useEffectOnce } from 'hooks/use-effect-once'

const placeholderSuggestions = [
  'used combines',
  '1025r',
  'current promotions',
  'stihl trimmers',
  'loaders',
  'gators',
  'utility tractors',
  'zero turns',
]

const getPlaceholder = () => {
  const suggestion = Math.floor(Math.random() * placeholderSuggestions.length)
  return `Try searching for ${placeholderSuggestions[suggestion]}`
}

const quickSearchSuggestions = [
  'compact utility tractors',
  'stihl chain saws',
  'lawn tractors',
  'zero turns',
  'gators',
  'used utility tractors',
  'current promotions',
]

const resultsPerPage = 12

let isSubscribed = true
let id = 0

const SearchPage = ({ data: { heroImage }, location }) => {
  const [active, setActive] = useState(false)
  const [results, setResults] = useState([])
  const [showingResults, setShowingResults] = useState([])
  const [showingAll, setShowingAll] = useState(true)
  const [page, setPage] = useState(1)
  const [ref, inView] = useInView()
  const [, setLoading] = useState(false)

  useEffectOnce(() => {
    isSubscribed = true
    return () => {
      isSubscribed = false
    }
  })

  useEffect(() => {
    if (inView) {
      loadMore()
    }
  }, [inView])

  const search = async (e, searchValue = '', replaceHistory = true) => {
    if (e) {
      e.preventDefault()
    }

    const q = {}
    let url = `/search`

    if (searchValue !== '') {
      q.search = searchValue
      url += `?search=${encodeURIComponent(searchValue)}`
    }

    if (replaceHistory) {
      navigate(url)
    }

    if (searchValue !== '') {
      setLoading(true)

      id += 1
      const currentId = id

      const searchResults = await fetch('/api/search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ query: searchValue }),
      })
        .then(res => {
          return res.json()
        })
        .catch(err => {
          console.error(err)
          return []
        })

      const filteredSearchResults = searchResults.filter(obj => !/used\spage/i.test(obj.tag))

      if (currentId === id && isSubscribed) {
        setLoading(false)
        setActive(true)
        setResults(filteredSearchResults)
        setPage(1)
        setShowing(1, filteredSearchResults)

        gtag('event', gtagEvents.search, {
          search_term: searchValue.trim().toLowerCase(),
        })

        fbq('track', fbqEvents.Search, {
          search_string: searchValue.trim().toLowerCase(),
        })
      }
    } else {
      setResults([])
      setActive(false)
      setLoading(false)
      setPage(1)
      setShowing(1, [])
    }
  }

  const loadMore = () => {
    const newPage = page + 1
    setPage(newPage)
    setShowing(newPage)
  }

  const setShowing = (newPage, newResults = results) => {
    const totalShowing = newPage * resultsPerPage
    const showingAll = totalShowing >= newResults.length
    const showingResults = newResults.slice(0, showingAll ? newResults.length : totalShowing)

    setShowingResults(showingResults)
    setShowingAll(showingAll)
  }
  return (
    <Layout>
      <Helmet>
        <title>Search | Hutson Inc</title>
        <meta
          name='description'
          content="Can't find what you're looking for? Search for new and used equipment, current promotions and offers, and more at Hutson."
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Search',
                'item': 'https://www.hutsoninc.com/search/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Search' image={heroImage} />

      <StyledContent id='top'>
        <StyledH2 textAlign='center'>What are you looking for?</StyledH2>
        <Form search={search} location={location} />
        {showingResults && showingResults.length > 0 && (
          <>
            <ShowingBar>
              <ShowingBarText>
                Found {results.length.toLocaleString('en-US')} results
              </ShowingBarText>
            </ShowingBar>
            {showingResults.map(page => (
              <Result key={page.slug} {...page} />
            ))}
          </>
        )}
        {!showingAll && (
          <div ref={ref}>
            <LoadMoreButton onClick={loadMore}>Load more</LoadMoreButton>
          </div>
        )}
        {results.length > 0 && showingAll && (
          <AllResultsLoadedContainer>
            <AllResultsLoaded>All results loaded.</AllResultsLoaded>
            <BackToTop>
              <a href='#top' onClick={scrollTo}>
                <IoIosArrowUp aria-hidden='true' focusable='false' role='presentation' />
                <BackToTopText>Back to top</BackToTopText>
              </a>
            </BackToTop>
          </AllResultsLoadedContainer>
        )}
        {active && results && results.length === 0 && (
          <NoneFoundContainer>
            <NoneFoundText>
              No results found. Can't find what you're looking for? Fill out{' '}
              <Link to='/contact/'>our contact form</Link> and we'll help you find it.
            </NoneFoundText>
          </NoneFoundContainer>
        )}
      </StyledContent>

      <LocationsSection />
    </Layout>
  )
}

const StyledContent = styled(Content)`
  max-width: 800px;
`

const StyledH2 = styled(H2)`
  font-size: 1.8em;

  hr {
    width: 80px;
  }
`

const NoneFoundContainer = styled.div`
  background-color: #f6f6f6;
  border: 1px solid #d7d7d7;
  font-size: 1.1em;
  margin-top: 30px;
  padding: 20px 30px;
`

const NoneFoundText = styled.p`
  line-height: 1.3;
  margin: 0;
`

const ShowingBar = styled.div`
  border-bottom: 1px solid #d7d7d7;
  color: #1a1a1a;
  margin: 30px 0 20px;
`

const ShowingBarText = styled.p`
  margin: 0 0 5px;
`

const LoadMoreButton = styled(Button)`
  margin: 0;
  max-width: 250px;
`

const AllResultsLoadedContainer = styled.div`
  font-size: 0.9em;
  margin-top: 30px;
`

const AllResultsLoaded = styled.div`
  color: #999;
  display: inline-block;
  font-style: italic;
  vertical-align: middle;
  width: 50%;
`

const BackToTop = styled.div`
  display: inline-block;
  text-align: right;
  vertical-align: middle;
  width: 49.99%;

  a {
    color: #999;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  svg {
    margin-right: 5px;
    vertical-align: middle;
  }
`

const BackToTopText = styled.span`
  vertical-align: middle;
`

const Form = ({ location, search }) => {
  const [query, setQuery] = useState('')
  const [placeholder, setPlaceholder] = useState('Try searching for zero turns')

  useEffectOnce(() => {
    window.addEventListener('popstate', onBackButtonEvent)

    const params = new URLSearchParams(location.search)
    const searchVal = params.get('search') || ''

    setQuery(searchVal)

    search(null, searchVal, false)

    if (searchVal === '') {
      setPlaceholder(getPlaceholder())
    }

    return () => {
      window.removeEventListener('popstate', onBackButtonEvent)
    }
  })

  const onBackButtonEvent = e => {
    const params = new URLSearchParams(window.location.search)
    const searchVal = params.get('search') || ''

    setQuery(searchVal)
    search(null, searchVal, false)
  }

  const handleChange = e => {
    const val = e.target.value

    setQuery(val)
  }

  const populateSearch = val => {
    setQuery(val)
    search(null, val)
  }

  const submit = e => {
    search(e, query)
  }
  return (
    <>
      <form onSubmit={submit}>
        <SearchInput
          type='search'
          value={query}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <SearchButton onClick={submit}>Search</SearchButton>
      </form>
      <SuggestionsList>
        {quickSearchSuggestions.map(suggestion => (
          <li key={`Suggestions_${suggestion}`}>
            <UnstyledButton onClick={() => populateSearch(suggestion)}>{suggestion}</UnstyledButton>
          </li>
        ))}
      </SuggestionsList>
    </>
  )
}

Form.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  search: PropTypes.func.isRequired,
}

const SearchInput = styled.input`
  border: 1px solid #d7d7d7 !important;
  font-size: 1.2em;
  height: 46px !important;
  margin-top: 0 !important;
  padding: 15px;

  @media (min-width: 800px) {
    border-right: none !important;
    display: inline-block !important;
    vertical-align: top;
    width: 75% !important;
  }
`

const SearchButton = styled(Button)`
  margin-top: 15px;

  @media (min-width: 800px) {
    display: inline-block;
    margin-top: 0;
    vertical-align: top;
    width: 24.99%;
  }
`

const SuggestionsList = styled.ul`
  margin: 15px 0 0;
  padding: 0;

  li {
    background-color: #efefef;
    color: #2a2a2a;
    cursor: pointer;
    display: inline-block;
    font-size: 0.9em;
    list-style: none;
    margin: 0 10px 10px 0;
    padding: 4px 8px;

    :hover {
      background-color: #dfdfdf;
    }
  }
`

const Result = ({ image, overview, slug, tag, title }) => {
  return (
    <ResultContainer>
      <Link to={slug}>
        <ResultInner>
          {image ? (
            <ResultImageContainer>
              <ResultImage src={image} alt='' loading='lazy' />
            </ResultImageContainer>
          ) : null}
          <ResultCopy>
            <ResultTitleContainer>
              <Tag tag={tag} type='mobile' />
              <ResultTitle>
                <ResultTitleText>{title}</ResultTitleText>
                <Tag tag={tag} type='desktop' />
              </ResultTitle>
            </ResultTitleContainer>
            <ResultDetails dangerouslySetInnerHTML={{ __html: overview }} />
          </ResultCopy>
        </ResultInner>
      </Link>
    </ResultContainer>
  )
}

Result.propTypes = {
  image: PropTypes.string,
  overview: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

const ResultContainer = styled.div`
  height: auto;
  margin: 0 auto 20px;
  max-width: auto;
  position: relative;
  text-align: left;
  width: 100%;

  a {
    color: #000;
    text-decoration: none;
  }
`

const ResultInner = styled.div`
  border: 1px solid #d7d7d7;
  box-shadow: none;
  margin: 0 0 20px;
  padding: 20px;

  @media (min-width: 800px) {
    display: flex;
    flex-direction: row;
    align-content: center;
    transition: box-shadow 0.2s;

    :hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
    }
  }
`

const ResultImageContainer = styled.div`
  @media (min-width: 800px) {
    display: inline-block;
    flex-shrink: 0;
    height: auto;
    margin: 0;
    margin-right: 2.5%;
    vertical-align: middle;
    width: 22.5%;
  }
`

const ResultImage = styled.img`
  max-width: 267px;
  width: 100%;

  @media (min-width: 800px) {
    display: block;
    margin: 0 auto;
    max-height: 150px;
    max-width: 100%;
    width: auto;
  }
`

const ResultCopy = styled.div`
  @media (min-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const ResultTitleContainer = styled.div`
  margin: 20px 0 5px;

  @media (min-width: 800px) {
    margin: 0 0 5px;
  }
`

const ResultTitle = styled.h3`
  color: #000;
  font-size: 1.25em;
  font-weight: 600;
  margin: 0;
  text-transform: none;
  vertical-align: middle;

  @media (min-width: 800px) {
    font-size: 1.5em;
  }
`

const ResultTitleText = styled.span`
  display: block;
  margin: 10px 0 0;

  @media (min-width: 800px) {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: middle;
  }
`

const ResultDetails = styled.div`
  max-height: 90px;
  overflow: hidden;
  position: relative;

  :before {
    background: linear-gradient(to bottom, transparent 50px, #fff);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  p {
    margin: 0;
  }

  li {
    margin: 0;
  }
`

const Tag = ({ tag, type }) => {
  if (tag === 'page') {
    return null
  }

  const style = {}

  if (tag === 'new' || tag === 'used') {
    style.backgroundColor = '#377539'
    style.color = '#fff'
  } else if (tag === 'stihl') {
    style.backgroundColor = '#f37a1f'
    styled.color = '#000'
  } else {
    style.backgroundColor = '#ffde00'
    styled.color = '#000'
  }
  return (
    <StyledTag style={style} type={type}>
      {tag}
    </StyledTag>
  )
}

Tag.propTypes = {
  tag: PropTypes.string,
  type: PropTypes.oneOf(['desktop', 'mobile']).isRequired,
}

const StyledTag = styled.span`
  display: ${props => (props.type === 'desktop' ? 'none' : 'inline-block')};
  font-size: ${props => (props.type === 'desktop' ? '0.5em' : '0.8em')};
  font-weight: bold;
  padding: 5px;
  text-transform: uppercase;
  vertical-align: middle;

  @media (min-width: 800px) {
    display: ${props => (props.type === 'mobile' ? 'none' : 'inline-block')};
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "search-header.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default SearchPage
