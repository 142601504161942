import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Button = ({ children, color, kind, type, ...props }) => {
  return (
    <StyledButton as={kind} color={color} type={type} {...props}>
      {children}
    </StyledButton>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(['green', 'gray', 'yellow']),
  kind: PropTypes.oneOf(['button', 'div']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
}

Button.defaultProps = {
  className: '',
  color: 'yellow',
  kind: 'button',
  type: 'submit',
}

const StyledButton = styled.div`
  background-color: ${props => {
    switch (props.color) {
      case 'green':
        return 'transparent'
      case 'gray':
        return '#dfdfdf'
      default:
        return '#ffdd00'
    }
  }};
  border: ${props => {
    switch (props.color) {
      case 'green':
        return '2px solid #377539'
      default:
        return 0
    }
  }};
  color: ${props => {
    switch (props.color) {
      case 'green':
        return '#377539'
      default:
        return '#000'
    }
  }};
  cursor: pointer;
  display: block;
  font-family: 'JD Sans', sans-serif;
  font-size: 0.9em;
  font-weight: bold;
  margin: auto;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.2s ease;
  width: 100%;

  :hover,
  :active,
  :focus {
    background-color: ${props => {
      switch (props.color) {
        case 'green':
          return '#377539'
        case 'gray':
          return '#d7d7d7'
        default:
          return '#ffcc00'
      }
    }};
    color: ${props => {
      switch (props.color) {
        case 'green':
          return '#fff'
        default:
          return '#000'
      }
    }};
  }
`

export default Button
