/**
 *
 * @param {string[]} arr List to convert
 * @param {object} [options] Options
 * @param {string} [options.conjunction=and] Conjunction
 * @param {boolean} [options.oxfordComma=true] Use an oxford comma
 * @param {string} [options.separator=,] List separator
 * @returns {string}
 */
const convertArrayToInlineList = (arr, options = {}) => {
  const { conjunction, oxfordComma, separator } = {
    conjunction: 'and',
    oxfordComma: true,
    separator: ',',
    ...options,
  }

  const itemCount = arr.length

  switch (itemCount) {
    case 0:
      return ''
    case 1:
      return arr[0]
    case 2:
      return `${arr[0]} ${conjunction} ${arr[1]}`
    default:
      return arr.reduce((acc, val, i) => {
        const isLastItem = i === arr.length - 1
        return `${acc}${isLastItem && !oxfordComma ? '' : separator}${
          isLastItem ? ` ${conjunction} ` : ' '
        }${val}`
      })
  }
}

module.exports = convertArrayToInlineList
