import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import H2 from 'components/new/typography/h2'

const SectionHeader = ({ children, type, ...props }) => {
  return (
    <StyledSectionHeader as={type} {...props}>
      {children}
      <Hr />
    </StyledSectionHeader>
  )
}

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['h1', 'h2']),
}

SectionHeader.defaultProps = {
  type: 'h2',
}

const StyledSectionHeader = styled(H2)`
  color: ${props => props.theme.color.n900};
  font-size: 32px;
  font-weight: 600;
  margin: 0;
  position: relative;
  text-align: center;
  text-transform: none;
`

const Hr = styled.hr`
  background-color: #377539;
  border: none;
  color: #377539;
  height: 2px;
  margin: ${props => `${props.theme.size.s} auto`};
  position: relative;
  width: 200px;
`

export default SectionHeader
