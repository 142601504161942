const scrollIntoView = require('scroll-into-view-if-needed').default

/**
 * Adds a smooth scroll to animation to an element
 * @param {Element} e - HTML element
 * @example
 * <a href="#top" onClick={scrollTo}>Back to top</a>
 * @returns {boolean} Returns true if successful or false otherwise
 */
const scrollTo = e => {
  const href = e.currentTarget.href

  const hash = href.substring(href.indexOf('#') + 1)

  if (hash) {
    const el = document.getElementById(hash)

    if (el) {
      e.preventDefault()

      scrollIntoView(el, {
        behavior: 'smooth',
        block: 'start',
      })

      return true
    }
  }

  return false
}

module.exports = scrollTo
